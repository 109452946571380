















































import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import ThingImage from '@/components/ThingImage/ThingImage.vue'
import store from '@/store'
import parseThing from '@/ts/parseThing'

@Component({
  components: { ThingImage },
  async beforeRouteEnter (to, from, next): Promise<void> {
    try {
      const contract = await store.getters['blockchain/contract']
      await contract.ownerOf(to.params.id)
      next()
    } catch (error) {
      next({ name: 'NonexistantThing' })
    }
  }
})
export default class Thing extends Vue {
  tokenId: number = parseInt(this.$route.params.id)
  dna?: number
  size?: number
  symmetry?: string
  latitude?: number
  longitude?: number
  name = `ThingZeta #${this.tokenId}`
  nameEdit = `ThingZeta #${this.tokenId}`
  description = ''
  descriptionEdit = ''
  createdAt = 0
  minter = '0x0000000'
  cleanMinter = '0x000…000'
  owner = '0x0000000'
  cleanOwner = '0x000…000'
  backgroundColor = '#fff'
  ready = false
  dayjs = dayjs
  ownedByMe = false
  isEditing = false
  isSaving = false

  async mounted (): Promise<void> {
    await this.getToken()
  }

  async getToken (): Promise<void> {
    const contract = await this.$store.getters['blockchain/contract']
    const thingData = await contract.getThing(this.tokenId)

    if (thingData.name !== '') {
      this.name = thingData.name
      this.nameEdit = thingData.name
    }

    if (this.$route?.meta?.title) this.$route.meta.title = `THINGS | ${this.name}`
    document.title = this.$route?.meta?.title

    this.description = thingData.description
    this.descriptionEdit = thingData.description
    this.createdAt = parseInt(thingData.createdAt._hex) * 1000
    this.minter = thingData.minter
    this.cleanMinter = await this.beautifyAddress(this.minter)
    this.owner = await contract.ownerOf(this.tokenId)
    this.cleanOwner = await this.beautifyAddress(this.owner)

    this.ownedByMe = this.owner === this.$store.state.blockchain.address

    const thing = parseThing(thingData.seed)

    this.dna = thing.dna
    this.size = thing.size
    this.symmetry = thing.symmetry
    this.latitude = thing.latitude
    this.longitude = thing.longitude

    if (thingData) this.ready = true
  }

  onGotBackground (color: string): void {
    this.backgroundColor = color
    this.$store.commit('ui/setHeaderTextColor', { left: color, right: '#fff' })
  }

  stopEditing (): void {
    this.isEditing = false
    this.nameEdit = this.name
    this.descriptionEdit = this.description
  }

  updateMetadata (): void {
    this.isSaving = true
    this.$store.dispatch('blockchain/updateMetadata', {
      tokenId: this.tokenId,
      name: this.nameEdit,
      description: this.descriptionEdit
    }).then((a) => {
      this.name = a.name
      this.description = a.description
      this.stopEditing()
      this.isSaving = false
    }).catch(error => {
      console.log(error)
      this.isSaving = false
    })
  }

  async beautifyAddress (address: string): Promise<string> {
    return await this.$store.state.blockchain.provider?.lookupAddress(address) || address.slice(0, 5) + '…' + address.slice(-3)
  }

  beautifyCoordinates (lat: number, lon: number): [string, string] {
    const latDeg = Math.floor(Math.abs(lat))
    const lonDeg = Math.floor(Math.abs(lon))
    const latMin = Math.floor((Math.abs(lat) - latDeg) * 60)
    const lonMin = Math.floor((Math.abs(lon) - lonDeg) * 60)
    const latSec = Math.round(((Math.abs(lat) - latDeg) * 60 - latMin) * 60)
    const lonSec = Math.round(((Math.abs(lon) - lonDeg) * 60 - lonMin) * 60)
    return [`${latDeg}°${latMin}'${latSec}"${lat > 0 ? 'N' : 'S'}`, `${lonDeg}°${lonMin}'${lonSec}"${lon > 0 ? 'E' : 'W'}`]
  }

  get showId (): boolean {
    return this.name !== `ThingZeta #${this.tokenId}`
  }
}
